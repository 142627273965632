:root {
    --theme-red: #FF315D;
    --theme-green: #8BC34A;
    --theme-blue: #00BCD4;
    --theme-yellow: #FBC02D;
    --theme-cherry: #EC407A;
    --theme-purple: #BA68C8;
    --theme-orange: #FF7043;
    --theme-indigo: #5C6BC0;
    --theme-light-blue: #29B6F6;
    --theme-grey: #555555;
    --theme-white: #FFFFFF;

    --theme-bg: var(--theme-white);
    --theme-text-color: var(--theme-grey);
}

.app {
    font-family: "Sarpanch", cursive;
    background-color: var(--theme-bg);
    min-height: 100vh;
    text-align: center;
    color: var(--theme-text-color);
    overflow-x: hidden;
    overflow-y: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.social {
    position: absolute;
    bottom: 5vh;
    right: 5vh;

    transform: translate(calc(100%)) rotate(-90deg);
    transform-origin: bottom left;
}

.menu-btn {
    position: absolute;
    right: 5vmin;
    top: 5vmin;
    width: 10vmin;
    cursor: pointer;
}

.themed-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.themed-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
}

.themed-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(
        -45deg,
        #FF315D 0%,
        #FF315D 20%,
        #8BC34A 20%,
        #8BC34A 40%,
        #5C6BC0 40%,
        #5C6BC0 60%,
        #FBC02D 60%,
        #FBC02D 80%,
        #FF7043 80%,
        #FF7043 100%
    )
}
