.links {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;

    font-family: 'Sarpanch', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.link {
    margin: 0px 10px;
    padding: 0px 10px;

    cursor: pointer;
}

.link a {
    text-decoration: none;
    color: var(--theme-grey);
}

.link:hover a{
    color: white;
}

#github:hover {
    background-color: #333333;
    /* this is github's brand color */
}

#twitter:hover {
    background-color: #1da1f2;
    /* this is twitter's brand color */
}

#instagram:hover {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #f56040, #f77737, #fcaf45, #ffdc80 );
    /* these are instagram's brand color */
}

#linkedin:hover {
    background-color: #0077b5;
    /* this is linkedin's brand color */
}
