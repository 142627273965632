html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
