.banner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-logo {
    width: 100%;
    height: 100%;
    fill: #555555;
}
