#border {
    fill: none;
    stroke: var(--theme-grey);
    stroke-width: 15;
    stroke-dasharray: 30;
}

#letters {
    fill: var(--theme-grey);
}

.menu-btn-label {
    font-family: 'Sarpanch', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
